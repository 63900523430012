import {
  ApolloQueryResult,
  OperationVariables,
  useQuery,
} from "@apollo/client";
import React, { createContext, useContext } from "react";
import { GetMeasurementStationQuery } from "utils/__generated__/GetMeasurementStationQuery";
import { GetMeasurementStations, GetMeasurementStations_station } from "utils/__generated__/GetMeasurementStations";
import {
  GetOrganizationDashboards,
  GetOrganizationDashboards_dashboard,
} from "utils/__generated__/GetOrganizationDashboards";
import {
  GET_MEASUREMENT_STATIONS,
  GET_ORGANIZATION_DASHBOARDS,
} from "utils/stations";

interface IStationsAndDashboardsContext {
  dashboards: GetOrganizationDashboards_dashboard[] | undefined;
  stationListData: GetMeasurementStations | undefined;
  organizationsStations: GetMeasurementStations_station[] | undefined;
  refetchDashboards: (
    variables?: Partial<OperationVariables> | undefined
  ) => Promise<ApolloQueryResult<GetOrganizationDashboards>>;
}

const StationsAndDashboardsContext = createContext<
  IStationsAndDashboardsContext | undefined
>(undefined);

export const StationsAndDashboardsProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const { data: dashboardData, refetch: refetchDashboards } =
    useQuery<GetOrganizationDashboards>(GET_ORGANIZATION_DASHBOARDS, {});
  const dashboards: GetOrganizationDashboards_dashboard[] | undefined =
    dashboardData?.dashboard;

  const { data: stationListData } = useQuery<GetMeasurementStations>(
    GET_MEASUREMENT_STATIONS
  );

  const organizationsStations = stationListData?.station.filter((st) => st.organization !== null)

  return (
    <StationsAndDashboardsContext.Provider
      value={{ dashboards, refetchDashboards, organizationsStations, stationListData }}
    >
      {children}
    </StationsAndDashboardsContext.Provider>
  );
};

export const useStationsAndDashboards = () => {
  const context = useContext(StationsAndDashboardsContext);
  if (!context) {
    throw new Error(
      "useStationsAndDashboards must be used within a StationsAndDashboardsProvider"
    );
  }
  return context;
};
