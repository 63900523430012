import React from "react";
import { gettext } from "i18n";
import { useQuery } from "@apollo/client";
import Form from "react-bootstrap/Form";
import { useAuth } from "state/auth";
import {
  GetManagedStationList,
  GetManagedStationListVariables,
} from "utils/__generated__/GetManagedStationList";
import {
  GetDatasourcesByStation,
  GetDatasourcesByStationVariables,
  GetDatasourcesByStation_datasource,
} from "utils/__generated__/GetDatasourcesByStation";
import {
  GET_MANAGED_STATION_LIST,
  GET_STATION_LIST,
  GET_DATASOURCES_BY_STATION,
} from "utils/stations";
import { GetUnits } from "utils/__generated__/GetUnits";
import { GET_UNITS } from "utils/units";
import { AdditionalDatapointData } from "./datacharts";
import { GetDatasourceData_source_data } from "./__generated__/GetDatasourceData";

/**
 * Dashboard widget related queries and utilities
 */

// Widget templates and the associated defaults (metaData) and parsers (metaDataParsers)
export const WIDGET_TEMPLATES: Record<
  string,
  string | number | object | any[]
>[] = [
  {
    key: "iframe_widget",
    name: "Inline Frame (iframe) Widget",
    metaData: {
      title: "iframe Widget",
      sourceUrl: "https://www.youtube.com/embed/1O1kDmfUG68",
      aspectWidth: 16,
      aspectHeight: 9,
    },
    metaDataParsers: {
      aspectWidth: (raw: string) => Number(raw),
      aspectHeight: (raw: string) => Number(raw),
      maxWidth: (raw: string) => Number(raw),
    },
    pickers: [],
  },
  {
    key: "primary_parameter_chart",
    name: "Primary Parameter Chart",
    metaData: {},
    metaDataParsers: {},
    pickers: [],
  },
  {
    key: "data_source_chart",
    name: "Data Source Chart",
    metaData: {
      title: "",
    },
    metaDataParsers: {},
    // Custom "data pickers" for a specific field.
    pickers: [
      {
        field: "sourceUUIDs",
        label: null,
        key: "multi_source_picker",
      },
    ],
  },
  {
    key: "stats_summary_widget",
    name: "Statistics Summary Widget",
    metaData: {},
    metaDataParsers: {},
    pickers: [],
  },
  {
    key: "primary_parameter_average_chart",
    name: "Primary Parameter Average Chart",
    metaData: {},
    metaDataParsers: {},
    pickers: {
      // {
      //   field: "stationId",
      //   label: gettext("Source Station"),
      //   key: "station_picker",
      // },
    },
  },
  {
    key: "daily_averages_table",
    name: "Daily Averages Table",
    metaData: {},
    metaDataParsers: {},
    pickers: [],
  },
  {
    key: "temperature_profile_widget",
    name: "Temperature Profile Widget",
    metaData: {},
    metaDataParsers: {
      // Make sure all entered stationIds are numbers
      addStationIds: (raw: string[]) => {
        return raw.map((r) => Number(r));
      },
    },
    pickers: [
      {
        field: "addStationIds",
        label: null,
        key: "multi_station_picker",
      },
    ],
  },
  {
    key: "total_daily_discharge_table",
    name: "Total Daily Discharge",
    metaData: {
      title: "",
    },
    metaDataParsers: {},
    pickers: [
      {
        field: "displayUnit",
        label: "Display unit",
        key: "unit_picker",
        filters: {
          displayUnit: (unit: any) => {
            if (!unit) {
              return false;
            }
            const allowed_units = ["l", "m³"];
            return allowed_units.includes(unit.symbol);
          },
        },
      },
      {
        field: "sourceUUIDs",
        label: null,
        filters: {
          datasource: (datasource: GetDatasourcesByStation_datasource) => {
            if (!datasource.unit) {
              return false;
            }
            const allowed_units = ["l/s"];
            return allowed_units.includes(datasource.unit.symbol);
          },
        },
        key: "multi_source_picker",
      },
    ],
  },
  {
    key: "daily_averages_multi_parameter",
    name: "Daily Averages Multi-Parameter",
    metaData: {
      title: "",
    },
    metaDataParsers: {},
    // Custom "data pickers" for a specific field.
    pickers: [
      {
        field: "sourceUUIDs",
        label: null,
        key: "multi_source_picker",
      },
    ],
  },
  {
    key: "total_monthly_discharge_table",
    name: "Total Monthly Discharge",
    metaData: {
      title: "",
    },
    metaDataParsers: {},
    pickers: [
      {
        field: "displayUnit",
        label: "Display unit",
        key: "unit_picker",
        filters: {
          displayUnit: (unit: any) => {
            if (!unit) {
              return false;
            }
            const allowed_units = ["l", "m³"];
            return allowed_units.includes(unit.symbol);
          },
        },
      },
      {
        field: "sourceUUIDs",
        label: null,
        filters: {
          datasource: (datasource: GetDatasourcesByStation_datasource) => {
            if (!datasource.unit) {
              return false;
            }
            const allowed_units = ["l/s"];
            return allowed_units.includes(datasource.unit.symbol);
          },
        },
        key: "multi_source_picker",
      },
    ],
  },
];

export interface DatasourcePickerProps {
  onChange: (e: any) => void;
  value: string;
  editedValues?: Record<string, any>;
}
export const DatasourcePicker = ({
  editedValues,
  onChange,
  value,
}: DatasourcePickerProps) => {
  const { metaData } = editedValues ?? {};
  const { stationId } = metaData ?? {};
  const { data: dataSourceData } = useQuery<
    GetDatasourcesByStation,
    GetDatasourcesByStationVariables
  >(GET_DATASOURCES_BY_STATION, {
    variables: {
      stationId: Number(stationId),
    },
    skip: !stationId,
  });

  if (!stationId) {
    return <></>;
  }

  const datasourceList = dataSourceData?.datasource;
  return (
    <Form.Select
      aria-label={gettext("Data Source")}
      onChange={onChange}
      value={value ?? ""}
    >
      <option value="">Please select a data source</option>
      {datasourceList?.map((datasource) => (
        <option
          key={`datasource_picker_${datasource.source_uuid}`}
          value={String(datasource.source_uuid)}
        >
          {datasource.name}
          {datasource.unit?.symbol ? ` (${datasource.unit.symbol})` : ""}
        </option>
      ))}
    </Form.Select>
  );
};

export interface MultiSourcePickerRowProps {
  field: string;
  value: string;
  filters?: Record<string, (item: any) => boolean>;
  rowIndex: number;
  editedValues: Record<string, any>;
  setEditedValues: React.Dispatch<React.SetStateAction<Record<string, any>>>;
  selectedStationIds: string[];
  setSelectedStationIds: React.Dispatch<React.SetStateAction<string[]>>;
}
export const MultiSourcePickerRow = ({
  rowIndex,
  field,
  value,
  filters,
  editedValues,
  setEditedValues,
  selectedStationIds,
  setSelectedStationIds,
}: MultiSourcePickerRowProps) => {
  const stationId = selectedStationIds[rowIndex];
  const { data: dataSourceData } = useQuery<
    GetDatasourcesByStation,
    GetDatasourcesByStationVariables
  >(GET_DATASOURCES_BY_STATION, {
    variables: {
      stationId: Number(stationId),
    },
    skip: !stationId,
  });

  const datasourceList = dataSourceData?.datasource;
  const existingValues = editedValues?.metaData?.[field] ?? [];
  const currentSourceUUID = value[rowIndex];
  const datasourceFilter = filters?.datasource ?? (() => true);
  return (
    <>
      <tr>
        <th colSpan={2}>
          <h5>
            {gettext("Data Source")} #{rowIndex + 1}{" "}
            {(rowIndex > 0 && gettext("(optional)")) || ""}
          </h5>
        </th>
      </tr>
      <tr>
        <th>{gettext("Source Station")}</th>
        <td>
          <StationPicker
            onChange={(e: any) => {
              const newStationId = e.target.value;
              const newSelectedStationIds = [...selectedStationIds];
              newSelectedStationIds[rowIndex] = newStationId;
              setSelectedStationIds(newSelectedStationIds);
            }}
            value={stationId}
          />
        </td>
      </tr>
      {!!stationId && (
        <tr>
          <th>{gettext("Data Source")}</th>
          <td>
            <Form.Select
              aria-label={gettext("Data Source")}
              onChange={(e: any) => {
                const value = e.target.value;
                const newExistingValues = [...existingValues];
                newExistingValues.splice(rowIndex, 1, value);
                setEditedValues({
                  ...editedValues,
                  metaData: {
                    ...editedValues.metaData,
                    [field]: newExistingValues,
                  },
                });
              }}
              value={currentSourceUUID ?? ""}
            >
              <option value="">Please select a data source</option>
              {datasourceList?.filter(datasourceFilter).map((datasource) => (
                <option
                  key={`datasource_picker_${datasource.source_uuid}`}
                  value={String(datasource.source_uuid)}
                >
                  {datasource.name}
                  {datasource.unit?.symbol
                    ? ` (${datasource.unit.symbol})`
                    : ""}
                </option>
              ))}
            </Form.Select>
          </td>
        </tr>
      )}
    </>
  );
};

export interface MultiSourcePickerProps {
  field: string;
  value: string;
  editedValues: Record<string, any>;
  setEditedValues: React.Dispatch<React.SetStateAction<Record<string, any>>>;
  filters?: Record<string, (item: any) => boolean>;
}
export const MultiSourcePicker = ({
  field,
  value,
  editedValues,
  setEditedValues,
  filters,
}: MultiSourcePickerProps) => {
  const { metaData } = editedValues ?? {};
  const { sourceUUIDs } = metaData ?? {};
  const [selectedStationIds, setSelectedStationIds] = React.useState<string[]>(
    []
  );
  const pickerCount = (sourceUUIDs?.length ?? 0) + 1;

  return (
    <>
      {Array.from(Array(pickerCount).keys()).map((index) => (
        <MultiSourcePickerRow
          key={`multi_source_picker_row_${index}`}
          field={field}
          value={value}
          filters={filters}
          rowIndex={index}
          editedValues={editedValues}
          setEditedValues={setEditedValues}
          selectedStationIds={selectedStationIds}
          setSelectedStationIds={setSelectedStationIds}
        />
      ))}
    </>
  );
};

export interface MultiStationPickerRowProps {
  field: string;
  value: string;
  rowIndex: number;
  editedValues: Record<string, any>;
  setEditedValues: React.Dispatch<React.SetStateAction<Record<string, any>>>;
}
export const MultiStationPickerRow = ({
  rowIndex,
  field,
  value,
  editedValues,
  setEditedValues,
}: MultiStationPickerRowProps) => {
  const existingValues = editedValues?.metaData?.[field] ?? [];
  const stationId = String(existingValues[rowIndex]) ?? "";
  return (
    <>
      <tr>
        <th colSpan={2}>
          <h5>
            {gettext("Source Station")} #{rowIndex + 1}{" "}
            {(rowIndex > 0 && gettext("(optional)")) || ""}
          </h5>
        </th>
      </tr>
      <tr>
        <th>{gettext("Station")}</th>
        <td>
          <StationPicker
            onChange={(e: any) => {
              const value = e.target.value;
              const newValues = [...existingValues];
              newValues.splice(rowIndex, 1, value);
              setEditedValues({
                ...editedValues,
                metaData: {
                  ...editedValues.metaData,
                  [field]: newValues,
                },
              });
            }}
            value={stationId}
          />
        </td>
      </tr>
    </>
  );
};

export interface MultiStationPickerProps {
  field: string;
  value: string;
  editedValues: Record<string, any>;
  setEditedValues: React.Dispatch<React.SetStateAction<Record<string, any>>>;
}
export const MultiStationPicker = ({
  field,
  value,
  editedValues,
  setEditedValues,
}: MultiStationPickerProps) => {
  const { metaData } = editedValues ?? {};
  const { addStationIds } = metaData ?? {};

  const pickerCount = (addStationIds?.length ?? 0) + 1;
  return (
    <>
      {Array.from(Array(pickerCount).keys()).map((index) => (
        <MultiStationPickerRow
          key={`multi_station_picker_row_${index}`}
          field={field}
          value={value}
          rowIndex={index}
          editedValues={editedValues}
          setEditedValues={setEditedValues}
        />
      ))}
    </>
  );
};

export interface StationPickerProps {
  onChange: (e: any) => void;
  value: string;
  byIdentifier?: boolean;
  filter?: (station: any) => boolean;
}
export const StationPicker = ({
  onChange,
  value,
  byIdentifier = false,
  filter = (station: any) => true,
}: StationPickerProps) => {
  const { user } = useAuth();
  const stationQuery = user?.admin
    ? GET_STATION_LIST
    : GET_MANAGED_STATION_LIST;
  const { data } = useQuery<
    GetManagedStationList,
    GetManagedStationListVariables
  >(stationQuery, {
    variables: {
      userId: user?.id ?? 0,
    },
  });
  const stationList = [...(data?.station ?? [])];
  // Sort stationList alphabetically by name
  stationList?.sort((a, b) => (a.name > b.name ? 1 : -1));
  const visibleStations = stationList?.filter(filter) ?? [];
  return (
    <>
      {!visibleStations.length && <div>No stations available</div>}
      {!!visibleStations.length && (
        <Form.Select
          aria-label={gettext("Source Station")}
          onChange={onChange}
          value={value ?? ""}
        >
          <option value="">Please select a station</option>
          {visibleStations.map((station) => (
            <option
              key={`station_picker_${station.id}`}
              value={
                byIdentifier ? String(station.identifier) : Number(station.id)
              }
            >
              {station.name} ({station.identifier})
            </option>
          ))}
        </Form.Select>
      )}
    </>
  );
};

export interface UnitPickerProps {
  onChange: (e: any) => void;
  value: string;
  filters?: Record<string, (item: any) => boolean>;
}
export const UnitPicker = ({ onChange, value, filters }: UnitPickerProps) => {
  const { data: unitQuery } = useQuery<GetUnits>(GET_UNITS);

  const filter = filters?.displayUnit ?? (() => true);
  const unitList = unitQuery?.unit ? [...unitQuery?.unit] : [];
  unitList.sort((a, b) => (a.id > b.id ? 1 : -1));
  const visibleUnits = unitList?.filter(filter) ?? [];
  return (
    <>
      {!!visibleUnits.length && (
        <Form.Select
          aria-label={gettext("Source Station")}
          onChange={onChange}
          value={value ?? ""}
        >
          <option value="">Please select a unit</option>
          {visibleUnits.map((unit) => (
            <option key={`unit_picker_${unit.id}`} value={unit.symbol}>
              {unit.symbol}
            </option>
          ))}
        </Form.Select>
      )}
    </>
  );
};

export interface PickerTemplateProps {
  pickerTemplate: string;
  onChange: (e: any) => void;
  field: string;
  value: string;
  editedValues: Record<string, any>;
  setEditedValues: React.Dispatch<React.SetStateAction<Record<string, any>>>;
  blankOptionLabel?: string;
  filters?: Record<string, (item: any) => boolean>;
}
export const PickerTemplate: React.FC<PickerTemplateProps> = ({
  pickerTemplate,
  onChange,
  field,
  value,
  editedValues,
  setEditedValues,
  filters,
}: PickerTemplateProps) => {
  switch (pickerTemplate) {
    case "station_picker":
      return <StationPicker onChange={onChange} value={value} />;
    case "datasource_picker":
      return (
        <DatasourcePicker
          onChange={onChange}
          value={value}
          editedValues={editedValues}
        />
      );
    case "unit_picker":
      return <UnitPicker onChange={onChange} value={value} filters={filters} />;
    case "multi_source_picker":
      return (
        <MultiSourcePicker
          field={field}
          value={value}
          editedValues={editedValues}
          setEditedValues={setEditedValues}
          filters={filters}
        />
      );
    case "multi_station_picker":
      return (
        <MultiStationPicker
          field={field}
          value={value}
          editedValues={editedValues}
          setEditedValues={setEditedValues}
        />
      );
    default:
      return null;
  }
};

export const calculateDailyStats = ({
  datapoints,
  startDate,
  endDate,
}: {
  datapoints: GetDatasourceData_source_data[];
  startDate: Date;
  endDate: Date;
}) => {
  const dailyStats: {
    [key: string]: {
      sum: number;
      count: number;
      min: number;
      max: number;
      invalid: boolean;
    };
  } = {};

  const startDateMidnight = new Date(startDate);
  const endDateMidnight = new Date(endDate);

  startDateMidnight.setHours(0, 0, 0, 0);
  endDateMidnight.setHours(0, 0, 0, 0);
  endDateMidnight.setDate(endDateMidnight.getDate() + 1); // Include the end date.

  datapoints.forEach((datapoint) => {
    const date = new Date(datapoint.timestamp);
    if (date < startDateMidnight || date >= endDateMidnight) return;

    const dateKey = `${date.getFullYear()}-${
      date.getMonth() + 1
    }-${date.getDate()}`;

    if (!dailyStats[dateKey]) {
      dailyStats[dateKey] = {
        sum: 0,
        count: 0,
        min: Infinity,
        max: -Infinity,
        invalid: false,
      };
    }

    dailyStats[dateKey].sum += datapoint.value;
    dailyStats[dateKey].count += 1;
    dailyStats[dateKey].min = Math.min(dailyStats[dateKey].min, datapoint.value);
    dailyStats[dateKey].max = Math.max(dailyStats[dateKey].max, datapoint.value);
    if (datapoint.invalid) {
      dailyStats[dateKey].invalid = true;
    }
  });

  return dailyStats;
};

export const formatDailyAverages = (
  dailyStats: {
    [key: string]: {
      sum: number;
      count: number;
      min: number;
      max: number;
      invalid: boolean;
    };
  }
) => {
  const dailyAverages: { [key: string]: number } = {};
  const dailyMins: { [key: string]: number } = {};
  const dailyMaxs: { [key: string]: number } = {};

  Object.entries(dailyStats).forEach(([dateKey, stats]) => {
    dailyAverages[dateKey] = stats.sum / stats.count;
    dailyMins[dateKey] = stats.min;
    dailyMaxs[dateKey] = stats.max;
  });

  let dailyAverageKeys = Object.keys(dailyAverages);
  dailyAverageKeys.sort((a, b) => {
    const dateA = new Date(a);
    const dateB = new Date(b);
    return dateB.getTime() - dateA.getTime();
  });

  return { dailyAverageKeys, dailyAverages, dailyMins, dailyMaxs };
};

export const formatAsDatasourceData = (
  dailyStats: {
    [key: string]: {
      sum: number;
      count: number;
      min: number;
      max: number;
      invalid: boolean;
    };
  }
): GetDatasourceData_source_data[] => {
  return Object.entries(dailyStats).map(([date, stats]) => ({
    __typename: "data",
    timestamp: date,
    value: stats.sum / stats.count,
    invalid: stats.invalid,
  }));
};
