import React from "react";
import { Routes as ReactRouterRoutes, Route, Navigate } from "react-router-dom";
import { useAuth } from "./state/auth";
import MainLayout from "./layouts/MainLayout";
import AuthSimpleLayout from "./theme/layouts/AuthSimpleLayout";
import PermissionContextCheck from "./components/auth/PermissionContextCheck";
import MeasurementStationsPage from "./pages/MeasurementStationsPage";
import MeasurementStationPage from "./pages/MeasurementStationPage";
import LoginPage from "./pages/LoginPage";
import ProfilePage from "./pages/ProfilePage";
import TokensPage from "./pages/TokensPage";
import PasswordRecoveryPage from "./pages/PasswordRecoveryPage";
import UserManagementPage from "./pages/UserManagementPage";
import OrganizationManagementPage from "./pages/OrganizationManagementPage";
import UserEditPage from "./pages/UserEditPage";
import ConversionTablePage from "./pages/ConversionTablePage";
import MeasurementStationFormulasPage from "./pages/MeasurementStationFormulasPage";
import MeasurementStationManualDatapointsPage from "./pages/MeasurementStationManualDatapointsPage";
import MeasurementStationEditPage from "./pages/MeasurementStationEditPage";
import MeasurementStationFilesPage from "./pages/MeasurementStationFilesPage";
import MeasurementStationDataUploadPage from "./pages/MeasurementStationDataUploadPage";
import LabDataUploadPage from "./pages/LabDataUploadPage";
import HMACPage from "./pages/admin/HMACPage";
import UnitEditPage from "./pages/admin/UnitEditPage";
import MapLayerEditPage from "./pages/admin/MapLayerEditPage";
import WorldsensingNetworkPage from "./pages/admin/WorldsensingNetworkPage";
import StationAuditPage from "./pages/StationAuditPage";
import isEmpty from "lodash/isEmpty";
import OrganizationEditPage from "./pages/OrganizationEditPage";
import DashboardListPage from "./dashboards/pages/DashboardListPage";
import DashboardDetailsPage from "./dashboards/pages/DashboardDetailsPage";
import DashboardAdminAddPage from "./dashboards/pages/DashboardAdminAddPage";
import { usePersistentState } from "./hooks/state";
import {
  faChartGantt,
  faFileUpload,
  faNetworkWired,
  faMapMarker,
} from "@fortawesome/free-solid-svg-icons";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { StaticTranslationTexts } from "./utils/staticTexts";

export interface RouteLeaf {
  name: string;
  icon?: any;
  active?: boolean;
  exact?: boolean;
  to: string;
  permission?: string;
}

export interface RouteGroup {
  name: string;
  active?: boolean;
  icon: any;
  children: RouteLeaf[];
  permission?: string;
}

export interface RouteCategory {
  label: string;
  labelDisable?: boolean;
  children: (RouteGroup | RouteLeaf)[];
}

export const routes: RouteCategory[] = [
  {
    label: "Map view",
    labelDisable: true,
    children: [
      {
        name: "Map view",
        to: "/stations",
        icon: "earth"
      }
    ]
  },
  {
    label: "Measurement stations",
    labelDisable: false,
    children: [
      {
        name: "...more",
        active: true,
        icon: "chart-line",
        to: "/stations",
      },
    ]
  },
  {
    label: "Dashboards",
    labelDisable: false,
    children: [
      {
        name: "...more",
        active: true,
        icon: "chart-pie",
        to: "/dashboards",
      },
    ]
  },
  {
    label: "",
    labelDisable: false,
    children: [
      {
        name: "Manual/lab data",
        active: true,
        icon: faChartGantt,
        to: "/stations/manual-datapoints",
      },
    ],
  },
  {
    label: "Profile",
    children: [
      {
        name: "My profile",
        icon: "user",
        to: "/profile",
      },
      {
        name: "Access tokens",
        icon: "check-circle",
        to: "/tokens",
      },
    ],
  },
  {
    label: "Organization",
    children: [
      {
        name: "User Management",
        icon: "user-plus",
        to: "/user-management",
        permission: "list_users",
      },
      {
        name: "Organization Management",
        icon: "users",
        to: "/organization-management",
        permission: "edit_organization",
      },
    ],
  },
];
export const adminRoutes: RouteCategory[] = [
  {
    label: "Admin",
    children: [
      {
        name: "HMAC",
        icon: "user-shield",
        to: "/admin/hmac",
      },
      {
        name: "Edit Units",
        icon: "percent",
        to: "/admin/units",
      },
      {
        name: "Import channel data",
        active: true,
        icon: faFileUpload,
        to: "/admin/stations/data-upload",
      },
      {
        name: "Import XML lab data",
        active: true,
        icon: faFileUpload,
        to: "/admin/xml-lab-data-upload",
      },
      {
        name: "Worldsensing Networks",
        active: true,
        icon: faNetworkWired,
        to: "/admin/worldsensing-networks",
      },
      {
        name: "Edit Map Layers",
        icon: faMapMarker,
        to: "/admin/map-layers",
      },
    ],
  },
];

const Routes = () => {
  const { user, loggedIn } = useAuth();
  const isAdmin = user?.admin;
  const isOrganizationMember = !isEmpty(user?.organizationRoles);
  const currentPath = window.location.pathname;
  const [rememberedPath, setRememberedPath] = usePersistentState(
    "rememberedPath",
    ""
  );

  if (!loggedIn && !currentPath.includes("/login") && !rememberedPath) {
    setRememberedPath(currentPath);
  }

  return (
    <ReactRouterRoutes>
      <Route element={<AuthSimpleLayout />}>
        <Route path="/login" element={<LoginPage />} />
        <Route
          path="/authentication/forgot-password"
          element={<PasswordRecoveryPage />}
        />
      </Route>
      {loggedIn ? (
        <Route element={<MainLayout />}>
          <Route path="/" element={<Navigate to="/stations" />} />
          <Route path="/dashboards" element={<DashboardListPage />} />
          <Route path="/dashboards/:id" element={<DashboardDetailsPage />} />
          <Route path="/stations" element={<MeasurementStationsPage />} />
          <Route
            path="/stations/:identifier"
            element={<MeasurementStationPage />}
          />
          {isAdmin && (
            <>
              <Route
                path="/stations/:identifier/conversions"
                element={<ConversionTablePage />}
              />
              <Route
                path="/stations/:identifier/formulas/:formulaId"
                element={<MeasurementStationFormulasPage />}
              />
              <Route
                path="/stations/:identifier/formulas"
                element={<MeasurementStationFormulasPage />}
              />
              <Route
                path="/stations/:identifier/edit"
                element={<MeasurementStationEditPage />}
              />
              <Route
                path="/admin/stations/data-upload"
                element={<MeasurementStationDataUploadPage />}
              />
              <Route
                path="/admin/xml-lab-data-upload"
                element={<LabDataUploadPage />}
              />
              <Route
                path="/admin/worldsensing-networks"
                element={<WorldsensingNetworkPage />}
              />
              <Route
                path="/dashboards/admin/add"
                element={<DashboardAdminAddPage />}
              />
              <Route path="/admin/hmac" element={<HMACPage />} />
              <Route path="/admin/map-layers" element={<MapLayerEditPage />} />
            </>
          )}
          <Route
            path="/admin/units"
            element={
              <PermissionContextCheck
                category="unit_edit"
                requiredPermission="read_access"
              >
                <UnitEditPage />
              </PermissionContextCheck>
            }
          />
          <Route
            path="/stations/manual-datapoints"
            element={<MeasurementStationManualDatapointsPage combined />}
          />
          <Route
            path="/stations/manual-datapoints/:datapointSetId"
            element={<MeasurementStationManualDatapointsPage combined />}
          />
          <Route
            path="/stations/manual-datapoints/:datapointSetId/edit-datapoints"
            element={
              <MeasurementStationManualDatapointsPage editDatapoints combined />
            }
          />
          <Route
            path="/stations/:identifier/manual-datapoints/:datapointSetId"
            element={<MeasurementStationManualDatapointsPage />}
          />
          <Route
            path="/stations/:identifier/manual-datapoints/:datapointSetId/edit-datapoints"
            element={<MeasurementStationManualDatapointsPage editDatapoints />}
          />
          <Route
            path="/stations/:identifier/manual-datapoints"
            element={<MeasurementStationManualDatapointsPage />}
          />
          <Route
            path="/user-management"
            element={
              <PermissionContextCheck
                category="user_edit"
                requiredPermission="read_access"
              >
                <UserManagementPage />
              </PermissionContextCheck>
            }
          />
          <Route
            path="/organization-management"
            element={
              <PermissionContextCheck
                category="organization_edit"
                requiredPermission="read_access"
              >
                <OrganizationManagementPage />
              </PermissionContextCheck>
            }
          />
          <Route
            path="/user/:identifier/edit"
            element={
              <PermissionContextCheck
                category="user_edit"
                requiredPermission="read_access"
              >
                <UserEditPage />
              </PermissionContextCheck>
            }
          />

          <Route
            path="/organization/:identifier/edit"
            element={
              <PermissionContextCheck
                category="organization_edit"
                requiredPermission="read_access"
              >
                <OrganizationEditPage />
              </PermissionContextCheck>
            }
          />
          {(isOrganizationMember || isAdmin) && (
            <>
              <Route
                path="/stations/:identifier/files"
                element={<MeasurementStationFilesPage />}
              />
              <Route
                path="/stations/:identifier/audit"
                element={<StationAuditPage />}
              />
              <Route
                path="/dashboards/admin/edit/:id"
                element={<DashboardDetailsPage editWidgets />}
              />
            </>
          )}
          <Route
            path="/stations/:identifier/organization-member-edit/"
            element={<MeasurementStationEditPage />}
          />
          <Route path="/profile" element={<ProfilePage />} />
          <Route path="/tokens" element={<TokensPage />} />
          <Route path="*" element={<p>404</p>} />
        </Route>
      ) : (
        <Route path="*" element={<Navigate to="/login" />} />
      )}
    </ReactRouterRoutes>
  );
};
export default Routes;
