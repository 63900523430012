import * as React from "react";
import apiClient from "api";
import { gettext } from "i18n";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import PageHeader from "../../theme/components/common/PageHeader";
import DashboardPreview from "../../theme/components/dashboards/default";

import { sortByKey } from "utils/formatting";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Flex from "theme/components/common/Flex";
import { useAuth, isOrgAdmin } from "state/auth";
import { useStationsAndDashboards } from "state/stationsAndDashboards";

const DashboardListPage = () => {
  const { user } = useAuth();
  const {dashboards, refetchDashboards} = useStationsAndDashboards()
  const isGlobalAdmin = !!user?.admin;


  const onDeleteDashboard = (id: string) => {
    if (
      !window.confirm(
        gettext("Are you sure? This action is permanent and cannot be undone.")
      )
    ) {
      return;
    }
    console.log("Delete dashboard with id: ", id);

    apiClient
      .request("/admin/delete-dashboard", {
        method: "POST",
        data: {
          id,
        },
      })
      .then(async () => {
        toast.success(gettext("Dashboard deleted successfully"));
        refetchDashboards();
      })
      .catch((e) => {
        console.error(e);
        toast.error(gettext(e.toString()));
      });
  };

  return (
    <div>
      {dashboards?.length ? (
        <>
          <PageHeader title="Dashboards" className="mb-3" />

          {isGlobalAdmin && (
            <Row className={"g-3 mb-3"}>
              <Col>
                <Card>
                  <Card.Body>
                    <Row>
                      <Col xs={12} sm={6}>
                        <div>
                          <strong>Dashboard administration</strong>
                        </div>
                      </Col>
                      <Col xs={12} sm={6}>
                        <Flex justifyContent="md-end">
                          <Button
                            as={Link as any}
                            to={`/dashboards/admin/add`}
                            variant="outline-primary"
                            size="sm"
                          >
                            Add
                          </Button>
                        </Flex>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          )}

          <Card className="mb-3">
            <Card.Body>
              <Table>
                <tbody>
                  {sortByKey(dashboards, "name").map((dashboard) => (
                    <tr key={dashboard.id}>
                      <td>
                        <Link to={`/dashboards/${dashboard.id}`}>
                          {dashboard.name}
                        </Link>
                      </td>
                      {(isGlobalAdmin ||
                        isOrgAdmin(user, dashboard.organization_id)) && (
                        <td>
                          <Flex justifyContent="md-end">
                            <div className="dashboard-row-buttons">
                              <Button
                                as={Link as any}
                                to={`/dashboards/admin/edit/${dashboard.id}`}
                                variant="outline-primary"
                                size="sm"
                              >
                                Edit widgets
                              </Button>
                              {isGlobalAdmin && (
                                <Button
                                  variant="danger"
                                  size="sm"
                                  onClick={() =>
                                    onDeleteDashboard(dashboard.id)
                                  }
                                >
                                  Delete
                                </Button>
                              )}
                            </div>
                          </Flex>
                        </td>
                      )}
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </>
      ) : (
        <>
          <PageHeader title="Coming soon" className="mb-3" />
          <DashboardPreview />
        </>
      )}
    </div>
  );
};

export default DashboardListPage;
