import React, { useContext, useEffect, Fragment } from "react";
import classNames from "classnames";
import { Nav, Navbar, Row, Col } from "react-bootstrap";
import { navbarBreakPoint, topNavbarBreakpoint } from "theme/config";
import AppContext from "theme/context/Context";
import Flex from "theme/components/common/Flex";
import Logo from "theme/components/common/Logo";
import { capitalize } from "theme/helpers/utils";
import bgNavbar from "theme/assets/img/generic/bg-navbar.png";
import NavbarTopDropDownMenus from "theme/components/navbar/top/NavbarTopDropDownMenus";
import NavbarVerticalMenu from "theme/components/navbar/vertical/NavbarVerticalMenu";
import ToggleButton from "theme/components/navbar/vertical/ToggleButton";
import { routes, adminRoutes } from "../../../routes";
import { useAuth } from "../../../state/auth";
import { gettext } from "../../../i18n";
import { useStationsAndDashboards } from "state/stationsAndDashboards";

interface NavbarVerticalProps {
  permissions?: string[];
}
const NavbarVertical: React.FC<NavbarVerticalProps> = ({
  permissions = [],
}: NavbarVerticalProps) => {
  const {
    config: {
      navbarPosition,
      navbarStyle,
      isNavbarVerticalCollapsed,
      showBurgerMenu,
      isDark,
    },
  } = useContext(AppContext);
  const { user } = useAuth();
  const {dashboards, organizationsStations} = useStationsAndDashboards()
  const allRoutes = user?.admin ? [...routes, ...adminRoutes] : routes;

  const dashboardShortcuts: { name: string; to: string; icon?: string, permission?: any }[] = dashboards
  ? dashboards.slice(0, 5).map((dashboard) => ({
      name: dashboard.name,
      to: `/dashboards/${dashboard.id}`,
      icon: "chart-pie"
    }))
  : [];

  const organizationStationsShortcuts: { name: string; to: string; icon?: string, permission?: any }[] = organizationsStations
  ? organizationsStations.slice(0, 5).map((station) => ({
      name: station.name,
      to: `/stations/${station.identifier}`,
      icon: "chart-line"
    }))
  : [];


    const updatedRoutes = allRoutes.map((route) => {
      if (route.label === "Dashboards") {
        return {
          ...route,
          children: [
            ...dashboardShortcuts,
            ...route.children,
          ],
        };
      } else if (route.label === "Measurement stations" ) {
        return {
          ...route,
          children: [
            ...organizationStationsShortcuts,
            ...route.children
          ]
        }
      }

      return route;
    });

  const filteredRoutes = updatedRoutes.filter((route) => {
    const routeChildren = route.children.filter(
      (child) => !child.permission || permissions.includes(child.permission)
    );
    return !!routeChildren.length;
  });

  const HTMLClassList = document.getElementsByTagName("html")[0].classList;

  useEffect(() => {
    if (isNavbarVerticalCollapsed) {
      HTMLClassList.add("navbar-vertical-collapsed");
    } else {
      HTMLClassList.remove("navbar-vertical-collapsed");
    }
    return () => {
      HTMLClassList.remove("navbar-vertical-collapsed-hover");
    };
  }, [isNavbarVerticalCollapsed, HTMLClassList]);

  //Control mouseEnter event
  let time: any = null;
  const handleMouseEnter = () => {
    if (isNavbarVerticalCollapsed) {
      time = setTimeout(() => {
        HTMLClassList.add("navbar-vertical-collapsed-hover");
      }, 100);
    }
  };
  const handleMouseLeave = () => {
    clearTimeout(time);
    HTMLClassList.remove("navbar-vertical-collapsed-hover");
  };

  const NavbarLabel = ({ label }: { label: string }) => (
    <Nav.Item as="li">
      <Row className="mt-3 mb-2 navbar-vertical-label-wrapper">
        <Col xs="auto" className="navbar-vertical-label navbar-vertical-label">
          {label}
        </Col>
        <Col className="ps-0">
          <hr className="mb-0 navbar-vertical-divider" />
        </Col>
      </Row>
    </Nav.Item>
  );

  return (
    <Navbar
      expand={navbarBreakPoint}
      className={classNames("navbar-vertical", {
        [`navbar-${navbarStyle}`]: navbarStyle !== "transparent",
      })}
      variant={isDark ? "dark" : "light"}
    >
      <Flex alignItems="center">
        <ToggleButton />
        <Logo at="navbar-vertical" width={40} />
      </Flex>
      <Navbar.Collapse
        in={showBurgerMenu}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        style={{
          backgroundImage:
            navbarStyle === "vibrant"
              ? `linear-gradient(-45deg, rgba(0, 160, 255, 0.86), #0048a2),url(${bgNavbar})`
              : "none",
        }}
      >
        <div
          className={classNames("navbar-vertical-content scrollbar", {
            card: navbarStyle === "card",
          })}
        >
          <Nav className="flex-column" as="ul">
            {filteredRoutes.map((route) => (
              <Fragment key={route.label}>
                {!route.labelDisable && (
                  <NavbarLabel label={capitalize(gettext(route.label))} />
                )}
                <NavbarVerticalMenu
                  routes={route.children.filter(
                    (child) =>
                      !child.permission ||
                      permissions.includes(child.permission)
                  )}
                />
              </Fragment>
            ))}
          </Nav>

          <>
            {navbarPosition === "combo" && (
              <div className={`d-${topNavbarBreakpoint}-none`}>
                <div className="navbar-vertical-divider">
                  <hr className="navbar-vertical-hr my-2" />
                </div>
                <Nav navbar>
                  <NavbarTopDropDownMenus />
                </Nav>
              </div>
            )}
          </>
        </div>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default NavbarVertical;
