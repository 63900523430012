import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import FormControl from "react-bootstrap/FormControl";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Table from "react-bootstrap/Table";
import ToggleButtonGroup from "react-bootstrap/ToggleButtonGroup";
import ToggleButton from "react-bootstrap/ToggleButton";
import { isEmpty, isMatch, pick } from "lodash";
import { ApolloError, gql, useQuery } from "@apollo/client";
import { gettext } from "../../i18n";
import {
  GetAlertByFormulaId,
  GetAlertByFormulaIdVariables,
} from "./__generated__/GetAlertByFormulaId";
import {
  GetAlertByChannelId,
  GetAlertByChannelId_alert,
  GetAlertByChannelIdVariables,
} from "./__generated__/GetAlertByChannelId";
import apiClient from "../../api";
import { toast } from "react-toastify";
import FormLabel from "react-bootstrap/FormLabel";
import {
  AlertGetFormulaById,
  AlertGetFormulaById_formula as Formula,
  AlertGetFormulaByIdVariables,
} from "./__generated__/AlertGetFormulaById";
import {
  AlertGetChannelById,
  AlertGetChannelById_channel as Channel,
  AlertGetChannelByIdVariables,
} from "./__generated__/AlertGetChannelById";
import DefaultTooltip from "../common/DefaultTooltip";
import { useModalContext } from "state/modal";
import "./AlertSettingForm.scss";

const GET_FORMULA_BY_ID_ALERT_FORM = gql`
  query AlertGetFormulaById($formulaId: Int) {
    formula(where: { id: { _eq: $formulaId } }) {
      __typename
      id
      name
      expression
      data_sources {
        id
        variable
        formula_id
        channel_id
        formula {
          id
          name
        }
        channel {
          id
          identifier
          name
        }
      }
    }
  }
`;
const GET_CHANNEL_BY_ID_ALERT_FORM = gql`
  query AlertGetChannelById($channelId: Int) {
    channel(where: { id: { _eq: $channelId } }) {
      __typename
      id
      identifier
      name
    }
  }
`;
const GET_ALERT_BY_FORMULA_ID = gql`
  query GetAlertByFormulaId($formulaId: Int) {
    alert(where: { formula_id: { _eq: $formulaId } }) {
      id
      lower_limit
      upper_limit
      channel_id
      formula_id
      active
      level
      alert_frequency_minutes
      alert_timeframe_minutes
      alert_recipients {
        id
        alert_id
        email_recipient
        sms_recipient
      }
      alert_options {
        id
        alert_id
        ignore_spikes
        trend_alarm
        trend_alarm_interval
        trend_alarm_interval_unit
      }
    }
  }
`;
const GET_ALERT_BY_CHANNEL_ID = gql`
  query GetAlertByChannelId($channelId: Int) {
    alert(where: { channel_id: { _eq: $channelId } }) {
      id
      lower_limit
      upper_limit
      channel_id
      formula_id
      active
      level
      alert_frequency_minutes
      alert_timeframe_minutes
      alert_recipients {
        id
        alert_id
        email_recipient
        sms_recipient
      }
      alert_options {
        id
        alert_id
        ignore_spikes
        trend_alarm
        trend_alarm_interval
        trend_alarm_interval_unit
      }
    }
  }
`;

declare type EventKey = string | number;

const INTERVAL_UNITS = ["minutes", "hours"];
export const ALERT_LEVELS = ["notice", "warning", "alert"];
export const ALERT_LEVEL_COLORS: Record<string, string> = {
  notice: "rgba(5, 5, 160, 1)",
  warning: "rgba(255, 60, 0, 1)",
  alert: "rgba(241, 5, 5, 1)",
};
export const ALERT_LEVEL_AREA_COLORS: Record<string, string> = {
  notice: "rgba(5, 5, 160, 0.1)",
  warning: "rgba(255, 133, 0, 0.2)",
  alert: "rgba(241, 5, 5, 0.1)",
};

interface AlertSettingFormProps {}

const ToastMessage = ({
  messageHeader,
  message,
}: {
  messageHeader: string;
  message: string;
}) => {
  return (
    <div>
      <p>{messageHeader}:</p>
      <p>{message}</p>
    </div>
  );
};

const AlertSettingForm = ({}: AlertSettingFormProps) => {
  const { setActiveModal, selectedDataSource } = useModalContext();
  const [tabKey, setTabKey] = React.useState<EventKey>("new");
  const [editingAlert, setEditingAlert] = React.useState<
    GetAlertByChannelId_alert | undefined
  >();
  const onHide = () => setActiveModal(null);
  const [alertValues, setAlertValues] = React.useState<Record<
    string,
    any
  > | null>(null);
  const [modalDisableSave, setModalDisableSave] = React.useState<boolean>(true);
  const defaultAlarmFrequencies = [
    ["15 m", 15],
    ["30 m", 30],
    ["1 h", 60],
    ["6 h", 6 * 60],
    ["24 h", 24 * 60],
    ["3 d", 3 * 24 * 60],
    ["7 d", 7 * 24 * 60],
  ];
  const defaultAlarmTimeframes = [
    ["15 m", 15],
    ["30 m", 30],
    ["1 h", 60],
    ["2 h", 2 * 60],
  ];
  const channelId =
    selectedDataSource && selectedDataSource.type === "channel"
      ? selectedDataSource.source_id!
      : null;
  const formulaId =
    selectedDataSource && selectedDataSource.type === "formula"
      ? selectedDataSource.source_id!
      : null;

  const dataSourceOptions = channelId
    ? { variables: { channelId } }
    : { variables: { formulaId } };
  const dataSourceStmt = channelId
    ? GET_CHANNEL_BY_ID_ALERT_FORM
    : GET_FORMULA_BY_ID_ALERT_FORM;
  const alertQueryOptions = channelId
    ? { variables: { channelId } }
    : { variables: { formulaId } };
  const alertQueryStmt = channelId
    ? GET_ALERT_BY_CHANNEL_ID
    : GET_ALERT_BY_FORMULA_ID;

  const { data: sourceData, error: sourceError } = useQuery<
    AlertGetFormulaById | AlertGetChannelById,
    AlertGetFormulaByIdVariables | AlertGetChannelByIdVariables
  >(dataSourceStmt, dataSourceOptions);
  const {
    data: alertData,
    error: alertError,
    refetch: alertRefetch,
  } = useQuery<
    GetAlertByFormulaId | GetAlertByChannelId,
    GetAlertByFormulaIdVariables | GetAlertByChannelIdVariables
  >(alertQueryStmt, alertQueryOptions);

  if (sourceError) {
    console.error("AlertSettingForm GQL error: ", sourceError);
    return <React.Fragment />;
  }

  if (!sourceData) {
    return <React.Fragment />;
  }
  const channel: Channel | undefined =
    "channel" in sourceData ? sourceData?.channel[0] : undefined;
  const formula: Formula | undefined =
    "formula" in sourceData ? sourceData?.formula[0] : undefined;
  const resource_key = `${selectedDataSource?.type}-${selectedDataSource?.source_id}`;
  const saveAlert = () => {
    if (alertValues) {
      const values = { ...alertValues };
      const requestPath = !editingAlert
        ? "/organization-admin/alert/create"
        : "/organization-admin/alert/edit";
      const successMessage = !editingAlert
        ? "Alert created successfully"
        : "Alert updated successfully";
      const errorMessage = !editingAlert
        ? "Error creating alert"
        : "Error updating alert";
      apiClient
        .request(requestPath, {
          method: "POST",
          data: {
            resource_key,
            alert_id: editingAlert?.id,
            source_uuid: selectedDataSource?.source_uuid,
            ...values,
          },
        })
        .then(() => {
          toast.success(
            <ToastMessage messageHeader={successMessage} message="" />
          );
          alertRefetch()
            .then(() => {
              setAlertValues(null);
              setModalDisableSave(true);
              setEditingAlert(undefined);
              setTabKey("list");
            })
            .catch((e) => {
              toast.error(
                <ToastMessage
                  messageHeader={
                    "Error when re-fetching the latest update of alert"
                  }
                  message={e.message.toString()}
                />
              );
            });
        })
        .catch((e) => {
          toast.error(
            <ToastMessage
              messageHeader={errorMessage}
              message={e.message.toString()}
            />
          );
        });
    }
  };
  const deleteAlert = () => {
    const alert_id = editingAlert?.id;
    const requestPath = "/organization-admin/alert/delete";
    const successMessage = "Alert deleted successfully";
    const errorMessage = "Error deleting alert";
    if (
      window.confirm(gettext("Are you sure you want to delete this alert?"))
    ) {
      apiClient
        .request(requestPath, {
          method: "POST",
          data: {
            resource_key,
            alert_id: alert_id,
          },
        })
        .then(() => {
          toast.success(
            <ToastMessage messageHeader={successMessage} message="" />
          );
          alertRefetch()
            .then(() => {
              setAlertValues(null);
              setModalDisableSave(true);
              setEditingAlert(undefined);
              setTabKey("list");
            })
            .catch((e) => {
              toast.error(
                <ToastMessage
                  messageHeader={
                    "Error when re-fetching the latest update of alert"
                  }
                  message={e.message.toString()}
                />
              );
            });
        })
        .catch((e) => {
          toast.error(
            <ToastMessage
              messageHeader={errorMessage}
              message={e.message.toString()}
            />
          );
        });
    }
  };
  return (
    <Modal
      show
      onHide={() => {
        setAlertValues(null);
        setModalDisableSave(true);
        onHide();
      }}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Alert Settings
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {channelId ? (
          <AlertChannelContent channel={channel} />
        ) : (
          <AlertFormulasContent formula={formula} />
        )}

        <Tabs
          activeKey={tabKey}
          onSelect={(key: string | null) => {
            setEditingAlert(undefined);
            if (key) {
              setTabKey(key);
            }
          }}
          defaultActiveKey="new"
          id="alert-settings-tabs"
          className="mb-3"
        >
          <Tab eventKey="new" title="Add new alert">
            <AlertConfigurationContent
              alertData={undefined}
              error={alertError}
              defaultAlarmFrequencies={defaultAlarmFrequencies}
              defaultAlarmTimeframes={defaultAlarmTimeframes}
              alertValues={alertValues}
              setAlertValues={setAlertValues}
              setModalDisableSave={setModalDisableSave}
            />
          </Tab>

          <Tab eventKey="list" title="Existing alerts">
            {(!editingAlert && (
              <div className="alert-list">
                {!alertData?.alert?.length && (
                  <p className="text-muted">No alerts found</p>
                )}
                {!!alertData?.alert?.length &&
                  [...alertData.alert]
                    .sort((a, b) => (a.id > b.id ? 1 : -1))
                    .map((alert) => (
                      <div
                        key={`alert_${alert.id}`}
                        onClick={() => setEditingAlert(alert)}
                      >
                        <div>{gettext(alert.level)}</div>
                        {!alert?.alert_timeframe_minutes && (
                          <div>Lower limit: {alert.lower_limit ?? "N/A"}</div>
                        )}
                        {alert?.alert_timeframe_minutes && (
                          <div>
                            Timeframe alarm threshold:{" "}
                            {alert.upper_limit ?? "N/A"}
                          </div>
                        )}
                        {!alert?.alert_timeframe_minutes && (
                          <div>Upper limit: {alert.upper_limit ?? "N/A"}</div>
                        )}
                        <div>
                          Frequency:{" "}
                          {alert.alert_frequency_minutes / 60 ?? "N/A"} h
                        </div>
                        {!!alert?.alert_timeframe_minutes && (
                          <div>
                            Timeframe:{" "}
                            {alert.alert_timeframe_minutes / 60 ?? "N/A"} h
                          </div>
                        )}
                      </div>
                    ))}
              </div>
            )) || (
              <>
                <AlertConfigurationContent
                  alertData={editingAlert}
                  error={alertError}
                  defaultAlarmFrequencies={defaultAlarmFrequencies}
                  defaultAlarmTimeframes={defaultAlarmTimeframes}
                  alertValues={alertValues}
                  setAlertValues={setAlertValues}
                  setModalDisableSave={setModalDisableSave}
                />
                <AlertRecipientsContent
                  alertData={editingAlert}
                  refetch={alertRefetch}
                  resourceKey={resource_key}
                />
              </>
            )}
          </Tab>
        </Tabs>
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={() => {
            setAlertValues(null);
            onHide();
          }}
        >
          Close
        </Button>

        {editingAlert && (
          <Button type="button" variant="danger" onClick={deleteAlert}>
            Delete
          </Button>
        )}

        {(tabKey === "new" || editingAlert) && (
          <Button type="button" onClick={saveAlert} disabled={modalDisableSave}>
            Save
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

interface AlertRecipientsContentProps {
  alertData: GetAlertByChannelId_alert | undefined;
  refetch: any;
  resourceKey: string;
}

interface EditRecipientRowActionName {
  save: string;
  delete: string;
  cancel: string;
  add: string;
}

const AlertRecipientsContent = ({
  alertData,
  refetch,
  resourceKey,
}: AlertRecipientsContentProps) => {
  const [editByRecipientId, setEditByRecipientId] = React.useState(null);
  const [newRecipient, setNewRecipient] = React.useState<Record<string, any>>(
    {}
  );
  const [editRecipient, setEditRecipient] = React.useState<Record<string, any>>(
    {}
  );
  // TODO: re-enable this when SMS works
  const enableSms = false;
  const setNewRecipientValues = (key: string) => (e: any) => {
    const value: string = e.target.value;
    if (!value) {
      delete newRecipient[key];
      setNewRecipient({
        ...newRecipient,
      });
    } else {
      setNewRecipient({
        ...newRecipient,
        [key]: value,
      });
    }
  };
  const editRecipientActions: EditRecipientRowActionName = {
    save: "save",
    delete: "delete",
    cancel: "cancel",
    add: "add",
  };
  const setEditRecipientValues = (key: string) => (e: any) => {
    const defaultValue: string = e.target.defaultValue;
    const value: string = e.target.value;
    if (defaultValue === value) {
      delete editRecipient[key];
      setEditRecipient({
        ...editRecipient,
      });
    } else {
      setEditRecipient({
        ...editRecipient,
        [key]: value,
      });
    }
  };
  const enableEditRow = (alert_recipient_id: any) => (e: any) => {
    setEditByRecipientId(alert_recipient_id);
  };
  const resetEditStates = () => {
    setEditByRecipientId(null);
    setEditRecipient({});
  };
  const resetAddStates = () => {
    setNewRecipient({});
  };
  const editActionHandler = (
    action: string,
    alertRecipientId: number | null = null
  ) => {
    switch (action) {
      case editRecipientActions.save:
        const values = { ...editRecipient };
        apiClient
          .request("/organization-admin/alert-recipient/edit", {
            method: "POST",
            data: {
              alert_recipient_id: editByRecipientId,
              resource_key: resourceKey,
              ...values,
            },
          })
          .then(() => {
            toast.success(
              <ToastMessage
                messageHeader="Recipient updated successfully"
                message="A test email was sent to the email address. Please check your spam folder, if you don't see the email in your inbox in a few minutes"
              />
            );
            refetch()
              .then(() => {
                resetEditStates();
              })
              .catch(console.error);
          })
          .catch((e) => {
            console.error(e);
            toast.error(
              <ToastMessage
                messageHeader="Error updating recipient"
                message={e.message.toString()}
              />
            );
          });
        break;

      case editRecipientActions.delete:
        apiClient
          .request("/organization-admin/alert-recipient/delete", {
            method: "POST",
            data: {
              alert_id: alertData?.id,
              alert_recipient_id: alertRecipientId,
              resource_key: resourceKey,
            },
          })
          .then(() => {
            toast.success(
              <ToastMessage
                messageHeader="Recipient deleted successfully"
                message=""
              />
            );
            refetch()
              .then(() => {
                resetAddStates();
              })
              .catch(console.error);
          })
          .catch((e) => {
            console.error(e);
            toast.error(
              <ToastMessage
                messageHeader={"Error deleting recipient"}
                message={e.message.toString()}
              />
            );
          });
        resetEditStates();
        break;

      case editRecipientActions.cancel:
        resetEditStates();
        break;

      case editRecipientActions.add:
        const newRecipientValues = { ...newRecipient };
        apiClient
          .request("/organization-admin/alert-recipient/create", {
            method: "POST",
            data: {
              alert_id: alertData?.id,
              resource_key: resourceKey,
              ...newRecipientValues,
            },
          })
          .then(() => {
            toast.success(
              <ToastMessage
                messageHeader="Recipient created successfully"
                message="A test email was sent to the email address. Please check your spam folder, if you don't see the email in your inbox in a few minutes"
              />
            );
            refetch()
              .then(() => {
                resetAddStates();
              })
              .catch(console.error);
          })
          .catch((e) => {
            console.error(e);
            toast.error(
              <ToastMessage
                messageHeader={"Error creating recipient"}
                message={e.message.toString()}
              />
            );
          });
        break;
    }
  };

  return (
    <>
      <h4>Recipients</h4>
      <Table>
        <tbody>
          <tr>
            <th>Email</th>
            <td>
              <FormControl
                value={newRecipient?.email_recipient ?? ""}
                placeholder={"Enter new email recipient"}
                onChange={setNewRecipientValues("email_recipient")}
              />
            </td>

            {enableSms && (
              <>
                <th>SMS</th>
                <td>
                  <FormControl
                    value={newRecipient?.sms_recipient ?? ""}
                    placeholder={"Enter new sms recipient"}
                    onChange={setNewRecipientValues("sms_recipient")}
                  />
                </td>
              </>
            )}
            <td>
              <Button
                disabled={Object.values(newRecipient).length === 0}
                onClick={() => editActionHandler(editRecipientActions.add)}
              >
                Add
              </Button>
            </td>
          </tr>
        </tbody>
      </Table>
      <Table>
        {!!alertData?.alert_recipients?.length && (
          <thead>
            <tr>
              <th>Email</th>
              {enableSms && <th>SMS</th>}
              <th>Action</th>
            </tr>
          </thead>
        )}
        {alertData?.alert_recipients?.map((alert_recipient, index) => {
          return (
            <tbody key={`${alert_recipient.__typename}-${alert_recipient.id}`}>
              {alert_recipient.id === editByRecipientId ? (
                <tr>
                  <td>
                    <FormControl
                      defaultValue={alert_recipient?.email_recipient ?? ""}
                      placeholder={"Enter email recipient"}
                      onChange={setEditRecipientValues("email_recipient")}
                    />
                  </td>
                  {enableSms && (
                    <td>
                      <FormControl
                        defaultValue={alert_recipient?.sms_recipient ?? ""}
                        placeholder={"Enter sms recipient"}
                        onChange={setEditRecipientValues("sms_recipient")}
                      />
                    </td>
                  )}
                  <td>
                    <Button
                      disabled={Object.values(editRecipient).length === 0}
                      onClick={() =>
                        editActionHandler(editRecipientActions.save)
                      }
                    >
                      Save
                    </Button>
                    <Button
                      onClick={() =>
                        editActionHandler(editRecipientActions.cancel)
                      }
                    >
                      Cancel
                    </Button>
                  </td>
                </tr>
              ) : (
                <tr>
                  <td>{alert_recipient.email_recipient}</td>
                  {enableSms && <td>{alert_recipient.sms_recipient}</td>}
                  <td>
                    <Button
                      onClick={enableEditRow(alert_recipient.id)}
                      className="me-2"
                    >
                      Edit
                    </Button>
                    <Button
                      variant={"danger"}
                      onClick={() =>
                        editActionHandler(
                          editRecipientActions.delete,
                          alert_recipient.id
                        )
                      }
                    >
                      Delete
                    </Button>
                  </td>
                </tr>
              )}
            </tbody>
          );
        })}
      </Table>
    </>
  );
};

interface AlertConfigurationContentProps {
  alertData: GetAlertByChannelId_alert | undefined;
  error: ApolloError | undefined;
  defaultAlarmFrequencies: (string | number)[][];
  defaultAlarmTimeframes: (string | number)[][];
  alertValues: Record<string, any> | null;
  setAlertValues: React.Dispatch<
    React.SetStateAction<Record<string, any> | null>
  >;
  setModalDisableSave: React.Dispatch<React.SetStateAction<boolean>>;
}

const AlertConfigurationContent = ({
  alertData,
  defaultAlarmFrequencies,
  defaultAlarmTimeframes,
  error,
  alertValues,
  setAlertValues,
  setModalDisableSave,
}: AlertConfigurationContentProps) => {
  const alertOptions = alertData?.alert_options[0];
  const alertLimitKeyNames = ["upper_limit", "lower_limit"];
  const [isTimeframeAlarm, setIsTimeframeAlarm] = useState(
    alertData?.alert_timeframe_minutes ? true : false
  );

  const setAlertValueHandler =
    (key: string, parser?: (raw: string) => any) => (e: any) => {
      let value = parser
        ? alertLimitKeyNames.includes(key) &&
          isEmpty(e.target.value) &&
          e.target.value === 0
          ? parser(e.target.value)
          : e.target.value
        : e.target.value;
      setAlertValue(key, value);
    };
  const resetLimits = (selected: boolean) => {
    if (!selected) return;
    delete alertValues?.["upper_limit"];
    delete alertValues?.["lower_limit"];
  };
  const alertValueUnchanged = (updatedOptions: any): boolean => {
    if (updatedOptions) {
      const pickKeys = Object.keys(updatedOptions);
      const initialOptions = pick(alertOptions, pickKeys);
      return (
        (!isEmpty(initialOptions) && isMatch(updatedOptions, initialOptions)) ||
        (!isEmpty(alertData) &&
          Object.values(alertData).includes(updatedOptions))
      );
    }
    return false;
  };
  const setAlertValue = (key: string, value: any) => {
    if (
      alertData &&
      Object.keys(alertData).includes(key) &&
      alertValueUnchanged(value) &&
      alertValues &&
      Object.keys(alertValues).includes(key)
    ) {
      delete alertValues[key];
      setModalDisableSave(Object.values(alertValues).length === 0);
      setAlertValues({ ...alertValues });
    } else {
      let isValid = false;
      if (["upper_limit", "lower_limit"].includes(key)) {
        isValid = validateLimits(key, value);
      }
      setAlertValues({
        ...alertValues,
        [key]: value,
      });
      !isValid && setModalDisableSave(false);
    }
  };
  const alertOptionsOnChange =
    (key: string, parser?: (raw: string) => any) => (e: any) => {
      const optionValue = parser ? parser(e.target.value) : e.target.checked;
      setAlertValue("alert_options", {
        ...alertValues?.alert_options,
        [key]: optionValue,
      });
    };
  if (error) {
    return (
      <Table>
        <tbody>
          <tr>
            <td>
              <pre>{JSON.stringify(error, null, 2)}</pre>
            </td>
          </tr>
        </tbody>
      </Table>
    );
  }
  const isValidDecimal = (value: string) => Number(value) === parseFloat(value);
  const limitsAreValid = () => {
    const lower_limit = alertValues?.lower_limit ?? alertData?.lower_limit;
    const upper_limit = alertValues?.upper_limit ?? alertData?.upper_limit;
    return isValidDecimal(lower_limit) && isValidDecimal(upper_limit);
  };
  const validateLimits = (key: string = "", value: any = null) => {
    const lower_limit =
      (key === "lower_limit" ? value : null) ??
      alertValues?.lower_limit ??
      alertData?.lower_limit;
    const upper_limit =
      (key === "upper_limit" ? value : null) ??
      alertValues?.upper_limit ??
      alertData?.upper_limit;

    if (
      (lower_limit && !isValidDecimal(lower_limit)) ||
      (upper_limit && !isValidDecimal(upper_limit))
    ) {
      setModalDisableSave(true);
      return false;
    }
    if (lower_limit && upper_limit) {
      if (
        Number(lower_limit) > Number(upper_limit) ||
        Number(upper_limit) < Number(lower_limit)
      ) {
        setModalDisableSave(true);
        return true;
      }
    }
    return false;
  };
  const EnabledText = <React.Fragment>enabled</React.Fragment>;
  const DisabledText = <React.Fragment>disabled</React.Fragment>;
  return (
    <>
      <h4>Configure Options</h4>
      <Table responsive>
        <tbody>
          <tr>
            <th>
              <Row>
                <Col>Alert status</Col>
                <Col>
                  <DefaultTooltip
                    popHeader="Alert status"
                    popBody={
                      <>
                        <p>Disabled alerts don't trigger</p>
                      </>
                    }
                  />
                </Col>
              </Row>
            </th>
            <td>
              <Form.Check
                checked={alertValues?.active ?? alertData?.active ?? true}
                type="switch"
                id="custom-switch-lower_limit_active"
                label={
                  alertValues?.active ?? alertData?.active ?? true
                    ? EnabledText
                    : DisabledText
                }
                onChange={(e) => setAlertValue("active", e.target.checked)}
              />
            </td>
          </tr>
          <tr>
            <th>
              <Row>
                <Col>Upper limit</Col>
                <Col>
                  <DefaultTooltip
                    popHeader="Upper limit"
                    popBody={
                      <>
                        <p>
                          Trigger alert if a value is greater than limit. Leave
                          empty to disable.
                        </p>
                      </>
                    }
                  />
                </Col>
              </Row>
            </th>
            <td>
              <Form.Group>
                <Form.Control
                  isInvalid={
                    !isValidDecimal(
                      alertValues?.upper_limit ?? alertData?.upper_limit ?? 0
                    ) || validateLimits()
                  }
                  value={
                    alertValues?.upper_limit ?? alertData?.upper_limit ?? ""
                  }
                  placeholder={gettext(
                    "Enter upper limit (leave empty to disable)"
                  )}
                  onChange={setAlertValueHandler(
                    "upper_limit",
                    parseFloatOrEmpty
                  )}
                  // disabled={isTimeframeAlarm} TODO: uncomment when timeframe alarm is fixed
                />
                {limitsAreValid() && (
                  <Form.Control.Feedback type="invalid">
                    <p>Upper limit can not be lower than lower limit.</p>
                    <p>Please change or leave it blank!</p>
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </td>
          </tr>
          <tr>
            <th>
              <Row>
                <Col>Lower limit</Col>
                <Col>
                  <DefaultTooltip
                    popHeader="Lower limit"
                    popBody={
                      <>
                        <p>
                          Trigger alert if a value falls below this limit. Leave
                          empty to disable.
                        </p>
                      </>
                    }
                  />
                </Col>
              </Row>
            </th>
            <td>
              <Form.Group>
                <FormControl
                  isInvalid={
                    !isValidDecimal(
                      alertValues?.lower_limit ?? alertData?.lower_limit ?? 0
                    ) || validateLimits()
                  }
                  value={
                    alertValues?.lower_limit ?? alertData?.lower_limit ?? ""
                  }
                  placeholder={gettext(
                    "Enter lower limit (leave empty to disable)"
                  )}
                  onChange={setAlertValueHandler(
                    "lower_limit",
                    parseFloatOrEmpty
                  )}
                  // disabled={isTimeframeAlarm} TODO: uncomment when timeframe alarm is fixed
                />
                {limitsAreValid() && (
                  <Form.Control.Feedback type="invalid">
                    <p>Lower limit can not be higher than upper limit.</p>
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </td>
          </tr>
          <tr>
            <th>Alarm frequency</th>
            <td>
              <ToggleButtonGroup
                id="measurement-group"
                name="measurement-group"
                type="radio"
                value={
                  alertValues?.alert_frequency_minutes ??
                  alertData?.alert_frequency_minutes ??
                  null
                }
                onChange={(value) => {
                  let val = parseFloat(value);
                  if (!val && !isNaN(val)) {
                    val = 0;
                  }
                  setAlertValue("alert_frequency_minutes", val);
                }}
              >
                {defaultAlarmFrequencies.map(([timeString, minute], index) => {
                  return (
                    <ToggleButton
                      size="sm"
                      key={`AlarmFrequencyKey-${index}`}
                      id={`AlarmFrequency-${index}`}
                      variant={"outline-primary"}
                      value={minute}
                    >
                      {timeString}
                    </ToggleButton>
                  );
                })}
              </ToggleButtonGroup>
            </td>
          </tr>
          {/* TODO: uncomment when timeframe alarm is fixed */}
          {/* <tr>
            <th>
              <Row>
                <Col>Alarm timeframe</Col>
                <Col>
                  <DefaultTooltip
                    popHeader="Alarm timeframe (optional)"
                    popBody={
                      <>
                        <p>
                          Trigger alert if a value changes for more than
                          speified value in seleced timeframe. Leave checkbox,
                          if you don't want to use this feature. Please note
                          that upper and lower limit will be ignored in this
                          case.
                        </p>
                      </>
                    }
                  />
                </Col>
              </Row>
            </th>
            <td>
              <Form.Check
                checked={isTimeframeAlarm}
                onChange={(e) => {
                  setIsTimeframeAlarm(e.target.checked);
                  resetLimits(e.target.checked);
                }}
                label="Timeframe alarm"
                type="checkbox"
              />
              <ToggleButtonGroup
                id="measurement-group"
                name="measurement-group"
                type="radio"
                value={
                  alertValues?.alert_timeframe_minutes ??
                  alertData?.alert_timeframe_minutes ??
                  null
                }
                onChange={(value) => {
                  let val = parseFloat(value);
                  if (!val && !isNaN(val)) {
                    val = 0;
                  }
                  setAlertValue("alert_timeframe_minutes", val);
                }}
              >
                {defaultAlarmTimeframes.map(([timeString, minute], index) => {
                  return (
                    <ToggleButton
                      disabled={!isTimeframeAlarm}
                      size="sm"
                      key={`AlarmTimeframeKey-${index}`}
                      id={`AlarmTimeframe-${index}`}
                      variant={"outline-primary"}
                      value={minute}
                    >
                      {timeString}
                    </ToggleButton>
                  );
                })}
              </ToggleButtonGroup>
              <FormControl
                isInvalid={
                  !isValidDecimal(
                    alertValues?.timeframe_alarm_threshold ??
                      alertData?.upper_limit ??
                      0
                  ) || validateLimits()
                }
                value={
                  alertValues?.timeframe_alarm_threshold ??
                  alertData?.upper_limit ??
                  ""
                }
                placeholder={gettext(
                  "Enter threshold value for triggering timeframe alarm"
                )}
                onChange={setAlertValueHandler(
                  "timeframe_alarm_threshold",
                  parseFloatOrEmpty
                )}
                disabled={!isTimeframeAlarm}
              />
            </td>
          </tr> */}
          <tr>
            <th>Alarm level</th>
            <td className="alertLevelSelect">
              <Form.Select
                required
                aria-label="Alarm level"
                onChange={setAlertValueHandler("level")}
                value={
                  alertValues?.level ?? alertData?.level ?? ALERT_LEVELS[0]
                }
              >
                {ALERT_LEVELS.map((level, index) => {
                  return (
                    <option key={`AlertLevelKey-${index}`} value={level}>
                      {gettext(level)}
                    </option>
                  );
                })}
              </Form.Select>
              <div
                className="alertColorPreview"
                style={{
                  backgroundColor:
                    ALERT_LEVEL_COLORS[
                      alertValues?.level ?? alertData?.level ?? ALERT_LEVELS[0]
                    ],
                }}
              />
            </td>
          </tr>
          <tr>
            <th>
              <Row>
                <Col>Ignore spikes</Col>
                <Col>
                  <DefaultTooltip
                    popHeader="Ignore spikes (optional)"
                    popBody={
                      <>
                        <p>
                          If selected, the alert triggers only after a set
                          number of consecutive results that exceed the
                          configured limits
                        </p>
                      </>
                    }
                  />
                </Col>
              </Row>
            </th>
            <td>
              {/*TODO: React does not like the mix of uncontrolled and controlled components. Fix the form handler.*/}
              <Form.Check
                checked={
                  alertValues?.alert_options?.ignore_spikes ??
                  alertOptions?.ignore_spikes ??
                  false
                }
                type="switch"
                id="custom-switch-ignore-spikes"
                label={
                  alertValues?.alert_options?.ignore_spikes ??
                  alertOptions?.ignore_spikes
                    ? EnabledText
                    : DisabledText
                }
                onChange={alertOptionsOnChange("ignore_spikes")}
              />
            </td>
          </tr>
          <tr>
            <th>
              <Row>
                <Col xs={"auto"}>Average alarm</Col>
                <Col xs={"auto"}>
                  <DefaultTooltip
                    popHeader="Average alarm (optional)"
                    popBody={
                      <>
                        <p>
                          Trigger the alert only based on the average during the
                          configured period (default 24h)
                        </p>
                      </>
                    }
                  />
                </Col>
              </Row>
            </th>
            <td>
              <Row>
                <Col xs={12}>
                  {/*TODO: React does not like the mix of uncontrolled and controlled components. Fix the form handler.*/}
                  <Form.Check
                    checked={
                      alertValues?.alert_options?.trend_alarm ??
                      alertOptions?.trend_alarm ??
                      false
                    }
                    type="switch"
                    id="custom-switch-trend-alarm"
                    label={
                      alertValues?.alert_options?.trend_alarm ??
                      alertOptions?.trend_alarm
                        ? EnabledText
                        : DisabledText
                    }
                    onChange={alertOptionsOnChange("trend_alarm")}
                  />
                </Col>
                <Col xs={12}>
                  <Row>
                    <Col xs={"auto"}>
                      <FormLabel htmlFor="trendInterval">Interval</FormLabel>
                    </Col>
                    <Col xs={"auto"} className="intervalUnitSelector">
                      <Form.Select
                        required
                        aria-label="Interval unit"
                        onChange={alertOptionsOnChange(
                          "trend_alarm_interval_unit",
                          (raw: string) => raw
                        )}
                        value={
                          alertValues?.alert_options
                            ?.trend_alarm_interval_unit ??
                          alertOptions?.trend_alarm_interval_unit ??
                          INTERVAL_UNITS[0]
                        }
                      >
                        <option value="minutes">Minutes</option>
                        <option value="hours">Hours</option>
                      </Form.Select>
                    </Col>
                    <Col xs={"auto"}>
                      <FormControl
                        type="text"
                        as={"input"}
                        htmlSize={4}
                        size={"sm"}
                        onChange={alertOptionsOnChange(
                          "trend_alarm_interval",
                          (x) => parseFloat(x) || null
                        )}
                        defaultValue={alertOptions?.trend_alarm_interval}
                        id="trendInterval"
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </td>
          </tr>
        </tbody>
      </Table>
    </>
  );
};

function parseFloatOrEmpty(value: string): number | string {
  if (!value) {
    return "";
  }
  const parsed = parseFloat(value);
  if (isNaN(parsed)) {
    return "";
  }
  return parsed;
}

interface AlertChannelContentProps {
  channel: Channel | undefined;
}

const AlertChannelContent = ({ channel }: AlertChannelContentProps) => {
  return (
    <>
      <h4>Channel</h4>
      <Table>
        <thead>
          <tr>
            <th>Identifier</th>
            <th>Name</th>
          </tr>
        </thead>
        <tbody>
          {channel ? (
            <tr key={`${channel.__typename}-${channel.id}`}>
              <td>{channel.identifier}</td>
              <td>{channel.name}</td>
            </tr>
          ) : (
            <tr>
              <td colSpan={2}>
                <i>Data not found</i>
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </>
  );
};

interface AlertFormulasContentProps {
  formula: Formula | undefined;
}

const AlertFormulasContent = ({ formula }: AlertFormulasContentProps) => {
  return (
    <>
      <h4>Formula</h4>
      <Table>
        <tbody>
          {formula ? (
            <tr key={`${formula.__typename}-${formula.id}`}>
              <td>{formula.name}</td>
            </tr>
          ) : (
            <tr>
              <td>
                <i>Data not found</i>
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </>
  );
};

export default AlertSettingForm;
